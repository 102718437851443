/*
    Composition API version of Pinia Stores.
    state (data of the store) == ref() or reactive()
    getter (computed properties -> does not directly modify the state) == computed()
    action (methods -> directly modifies the state) == function()
*/

import { defineStore } from "pinia";

export const useMetaStore = defineStore("metaStore", () => {
  // state
  const pageTitle = ref(
    "Australia's Most Trusted nbn® Internet Provider | Aussie Broadband",
  );
  const metaTitle = ref(
    "Australia's Most Trusted nbn® Internet Provider | Aussie Broadband",
  );
  const metaDescription = ref(
    "Award-winning nbn® internet, mobile, and network services for Aussie homes, business, and enterprise. That's Aussie Broadband, that's The Actual Aussie Way.",
  );

  return { pageTitle, metaTitle, metaDescription };
});
